import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { ContactForm } from './Forms/ContactForm'
import { glassmorph } from './GlobalStyles/glassmorph'
import { graphql, useStaticQuery } from 'gatsby'

const Container = styled.div`
  position: relative;
  min-height: 350px;

  .img {
    min-height: 450px;
  }
`

const Glass = styled.div`
  ${glassmorph}
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
`

interface Props {
  className?: string
}

export const HomeContactForm = ({ className }: Props): JSX.Element => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "HY_1107.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container className={className}>
      <Img className="img" fluid={data.file.childImageSharp.fluid} alt="The old terminal at the Lunken Airport" />
      <Glass>
        <ContactForm />
      </Glass>
    </Container>
  )
}
