import React from 'react'
import styled from 'styled-components'
import { Icon } from './Icon/Icon'
import { colors } from './GlobalStyles/vars/colors'
import { InLink } from './atoms/ui/links'
import { device } from './GlobalStyles/helpers/device'

const Container = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 0 auto;
  fill: ${colors.white};
  color: ${colors.white};

  @media ${device.tablet} {
    width: 80%;
  }
`

const Item = styled.li`
  width: 20%;
  background-color: ${colors.primary};
  border-radius: 100%;
  padding: 20px 30px;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    :hover {
      color: ${colors.grey};
    }

    span {
      margin-top: 5px;
    }
  }
`

interface Props {
  className?: string
}

export const HomePageIcons = ({ className }: Props): JSX.Element => {
  return (
    <Container className={className}>
      <Item>
        <InLink to="services">
          <Icon symbol="service" />
          <span hidden>Services</span>
        </InLink>
      </Item>
      <Item>
        <InLink to="fleet">
          <Icon symbol="fleet" />
          <span hidden>Fleet</span>
        </InLink>
      </Item>
      <Item>
        <InLink to="partners">
          <Icon symbol="partner" />
          <span hidden>Partners</span>
        </InLink>
      </Item>
    </Container>
  )
}
