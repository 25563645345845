import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { truncate } from '../../helpers/truncate'
import { H2 } from '../atoms/typo/typo'
import { InLink } from '../atoms/ui/links'
import { Listing } from '../Listing'
import { Author } from './blogTypes'

const Container = styled.div`
  padding: 0 10px;

  .more-link {
    font-size: 0.8em;
    display: block;
    width: fit-content;
    margin-left: auto;
    margin-right: 45px;
    margin-top: 10px;
  }
`

interface Props {
  className?: string
}

interface Post {
  title: string
  author: Author
  _rawDescription: React.ReactNode
  mainImage: {
    asset: {
      fixed: {
        src: string
      }
    }
    alt: string
  }
  slug: {
    current: string
  }
  publishedAt: string
  id: string
}

export const BlogRecent = ({ className }: Props): JSX.Element => {
  const data = useStaticQuery(graphql`
    {
      allSanityPost(limit: 3, sort: { fields: publishedAt, order: DESC }) {
        nodes {
          title
          author {
            name
          }
          _rawDescription
          mainImage {
            asset {
              fixed(width: 100, height: 100) {
                src
              }
            }
            alt
          }
          slug {
            current
          }
          publishedAt(formatString: "MM-YYYY")
          id
        }
      }
    }
  `)

  const posts = data.allSanityPost.nodes

  return (
    <Container className={className}>
      <H2>Blog Posts</H2>

      <ul>
        {posts.map(
          (post: Post): JSX.Element => {
            const img = post.mainImage

            return (
              <Listing
                title={truncate(post.title, 50)}
                key={post.id}
                img={img?.asset.fixed.src}
                alt={img?.alt}
                subtitle={`${post.publishedAt} | ${post.author.name}`}
                link={`/blog/${post.publishedAt}/${post.slug.current}/`}
                desc={post?._rawDescription}
              />
            )
          },
        )}
      </ul>

      <InLink className="more-link" to="/blog">
        Read more...
      </InLink>
    </Container>
  )
}
