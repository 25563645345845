import React from 'react'
import { WiBarometer } from 'react-icons/wi'
import { Condition } from './Condition'

interface Props {
  altim_in_hg: number
}

export const Pressure = ({ altim_in_hg }: Props): JSX.Element => {
  return (
    <Condition>
      {altim_in_hg > 31 ? <WiBarometer style={{ fill: 'red' }} /> : <WiBarometer />}
      <span>Pressure</span>
      <span>{altim_in_hg.toFixed(2)}</span>
    </Condition>
  )
}
