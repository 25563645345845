import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  margin-top: 10px;
`

const Wrapper = styled.div`
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
`

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

interface Props {
  className?: string
}

export const HeroVideo = ({ className }: Props): JSX.Element => {
  return (
    <Container className={className}>
      <Wrapper>
        <Iframe
          title="Tidy Tim Ad"
          frameBorder="0"
          width="720"
          height="405"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; autoplay=1;"
          src="https://www.youtube.com/embed/SovzfTHLBto?modestbranding=1&amp;autoplay=1&amp;controls=0&amp;wmode=transparent&amp;hd=1&amp;rel=0&amp;autohide=1&amp;showinfo=0&amp;loop=1&amp;mute=1"
        />
      </Wrapper>
    </Container>
  )
}
