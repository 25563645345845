import React from 'react'
import { WiCloudy } from 'react-icons/wi'
import { Condition } from './Condition'

interface SkyCover {
  sky_cover: string
  cloud_base_ft_agl: string
}

interface Props {
  sky_condition: SkyCover | SkyCover[]
}

const layers = (sky_condition: SkyCover | SkyCover[]): JSX.Element => {
  if (Array.isArray(sky_condition)) {
    return sky_condition.map((clouds) => (
      <span key={Math.random()}>{clouds.sky_cover + ' ' + clouds.cloud_base_ft_agl}</span>
    ))
  } else {
    return <span>{sky_condition.sky_cover + ' ' + sky_condition.cloud_base_ft_agl}</span>
  }
}

export const Clouds = ({ sky_condition }: Props): JSX.Element => {
  return (
    <Condition>
      <WiCloudy />
      <span>Clouds</span>
      {layers(sky_condition)}
    </Condition>
  )
}
