import React from 'react'
import {
  WiDayLightWind,
  WiDaySunny,
  WiFog,
  WiHail,
  WiLightning,
  WiNa,
  WiRain,
  WiSandstorm,
  WiSmog,
  WiSnow,
  WiTornado,
} from 'react-icons/wi'
import { Condition } from './Condition'

interface Props {
  wx_string?: string
  visibility_statute_mi: number
}

export const Precip = ({ wx_string, visibility_statute_mi }: Props): JSX.Element => {
  const cleanWx = wx_string.replace(/[^A-Za-z]/, '').substr(0, 2)
  let icon = <WiDayLightWind />
  let span = ''

  switch (cleanWx) {
    case 'SK': {
      icon = <WiDaySunny />
      break
    }

    case 'BR': {
      icon = <WiFog />
      span = 'Mist'
      break
    }

    case 'FG': {
      icon = <WiFog />
      span = 'Fog'
      break
    }

    case 'HZ':
    case 'FU': {
      icon = <WiSmog />
      span = 'Haze'
      break
    }

    case 'RA':
    case 'DZ':
    case 'SH': {
      icon = <WiRain />
      span = 'Rain'
      break
    }

    case 'FZ': {
      icon = <WiRain />
      span = 'Freezing Precip.'
      break
    }

    case 'GR':
    case 'GS':
    case 'PL': {
      icon = <WiHail />
      span = 'Hail'
      break
    }

    case 'SN':
    case 'DR':
    case 'SG':
    case 'BC': {
      icon = <WiSnow />
      span = 'Snow'
      break
    }

    case 'PO':
    case 'SS':
    case 'DU':
    case 'DS':
    case 'SA':
    case 'VA': {
      icon = <WiSandstorm />
      span = 'Sand/Dust'
      break
    }

    case 'FC': {
      icon = <WiTornado />
      span = 'Funnel Clouds'
      break
    }

    case 'TS': {
      icon = <WiLightning />
      span = 'Thunder Storms'
      break
    }

    default: {
      icon = <WiNa />
      span = wx_string
    }
  }

  return (
    <Condition>
      {icon}
      <span>Visibility</span>
      <span>
        {visibility_statute_mi}SM {span}
      </span>
    </Condition>
  )
}
