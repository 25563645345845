import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import parser from 'fast-xml-parser'
import { Precip } from './molecules/Precip'
import { Temp } from './molecules/Temp'
import { Clouds } from './molecules/Clouds'
import { Wind } from './molecules/Wind'
import { Pressure } from './molecules/Pressure'

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: center;
`

const Weather = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
  padding-left: 30px;
  padding-right: 30px;
`

interface Props {
  icao: string
  className?: string
}

export const AirportWeather = ({ icao, className }: Props): JSX.Element => {
  const options = {
    url: 'https://cors-anywhere.herokuapp.com/https://www.aviationweather.gov/adds/dataserver_current/httpparam',
    params: {
      dataSource: 'metars',
      requestType: 'retrieve',
      format: 'xml',
      hoursBeforeNow: '1.5',
      stationString: icao,
      mostRecent: true,
    },
    headers: {
      'Content-Type': 'application/xml',
    },
  }

  interface SkyCover {
    sky_cover: string
    cloud_base_ft_agl: string
  }

  interface Weather {
    raw_text: string
    station_id: string
    observation_time: string //2020-12-29T00:01:00Z
    latitude: number
    longitude: number
    temp_c: number
    dewpoint_c: number
    wind_dir_degrees: number
    wind_speed_kt: number
    visibility_statute_mi: number
    altim_in_hg: number
    quality_control_flags: {
      auto: number
      auto_station: number
    }
    wx_string: string //-SN BR
    flight_category: string
    precip_in: number
    metar_type: string
    elevation_m: number
    sky_condition: SkyCover | [SkyCover]
  }

  const [weather, setWeather] = useState<Weather>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)

    //request from the ADDS text-server like its 1904
    axios
      .request(options)
      .then((response) => {
        const data = parser.parse(response.data, {
          ignoreAttributes: false,
          attributeNamePrefix: '',
        })
        setWeather(data.response.data.METAR)
        setLoading(false)
      })
      //add more error checking---------------------------------------------------
      .catch((error) => {
        console.error(error)
      })
  }, [])

  return (
    <Container className={className}>
      <h2>
        {loading
          ? `Loading Weather`
          : `${weather && weather.station_id.substring(1)} Weather` || 'Failed to Load Weather'}
      </h2>
      {loading === false && weather && (
        <Weather>
          <Clouds sky_condition={weather.sky_condition} />
          <Precip visibility_statute_mi={weather.visibility_statute_mi} wx_string={weather.wx_string || 'SK'} />
          <Wind wind_dir_degrees={weather.wind_dir_degrees} wind_speed_kt={weather.wind_speed_kt} />
          <Pressure altim_in_hg={weather.altim_in_hg} />
          <Temp temp={weather.temp_c} label="Temperature" />
          <Temp temp={weather.dewpoint_c} label="Dewpoint" />
        </Weather>
      )}
    </Container>
  )
}
