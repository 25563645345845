import React from 'react'
import { WiThermometer, WiThermometerExterior } from 'react-icons/wi'
import { c2f } from '../helpers/c2f'
import { Condition } from './Condition'

interface Props {
  temp: number
  label: string
}

export const Temp = ({ temp, label }: Props): JSX.Element => {
  return (
    <Condition>
      {temp > 0 ? <WiThermometer /> : <WiThermometerExterior />}
      <span>{label}</span>
      <span>
        {temp}&#176;C/{c2f(temp)}&#176;F
      </span>
    </Condition>
  )
}
