import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { H2 } from '../components/atoms/typo/typo'
import { graphql, useStaticQuery } from 'gatsby'
import { BlockContent } from './BlockContent'
import { device } from './GlobalStyles/helpers/device'
import { glassmorph } from './GlobalStyles/glassmorph'
import { NavButton } from './atoms/ui/buttons'
import { colors } from './GlobalStyles/vars/colors'

const Container = styled.section`
  position: relative;

  a {
    margin-left: 5%;
  }

  img {
    filter: brightness(70%);
  }
`
const Wrapper = styled.div`
  @media ${device.tablet} {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const Glass = styled.div`
  @media ${device.tablet} {
    ${glassmorph}
    padding: 0 50px;
    padding-bottom: 50px;
    margin: 20px;
    max-width: 700px;
    color: ${colors.brightWhite};
  }
`

interface Props {
  body: React.ReactNode
}

export const HomeAbout = ({ body }: Props): JSX.Element => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "luk_21l.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Img fluid={data.file.childImageSharp.fluid} alt="Runways seen from the air at the lunken airport." />
      <Wrapper>
        <Glass>
          <H2>The Perfect Location</H2>
          <BlockContent body={body} />
          <NavButton to="/services">Explore our Services</NavButton>
        </Glass>
      </Wrapper>
    </Container>
  )
}
