import React from 'react'
import styled from 'styled-components'
import { NavButton } from '../components/atoms/ui/buttons'
import { BlockContent } from './BlockContent'
import { fontSizes } from './GlobalStyles/vars/fontSizes'

const Container = styled.div`
  text-align: center;

  a {
    margin: 0 auto;
  }

  h1 {
    display: block;
    margin-top: 30px;
  }

  h2 {
    margin-top: -4px;
    font-size: ${fontSizes.md};
  }

  div {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
`

interface Props {
  className?: string
  title: string
  subtitle: string
  body: React.ReactNode
}

export const HomeLead = ({ body, title, subtitle, className }: Props): JSX.Element => {
  return (
    <Container className={className}>
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
      <BlockContent body={body} />
      <NavButton to="/contact-us">Learn to Fly!</NavButton>
    </Container>
  )
}
