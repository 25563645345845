import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Layout } from '../components/Layout/Layout'
import { HeroVideo } from '../components/HeroVideo'
import { HomeAbout } from '../components/HomeAbout'
import { BlogRecent } from '../components/blog/BlogRecent'
import { AirportWeather } from '../components/AirportWeather/AirportWeather'
import { BlockContent } from '../components/BlockContent'
import { HomeContactForm } from '../components/HomeContactForm'
import { SEO } from '../helpers/SEO'
import { device } from '../components/GlobalStyles/helpers/device'
import { HomeLead } from '../components/HomeLead'
import { Block } from '../helpers/toPlainText'
import { HomePageIcons } from '../components/HomePageIcons'

const PageWrapper = styled.div`
  section {
    margin-bottom: 45px;
  }

  .icons {
    display: none;
    margin-bottom: 45px;
  }

  @media ${device.mobileL} {
    .icons {
      display: flex;
    }
  }

  @media ${device.laptop} {
    .reverse {
      flex-direction: row-reverse;
    }

    .tight-left {
      padding-left: 30px;
      //padding-right: 30px;
    }
  }
`

const SideBySide = styled.section`
  @media ${device.laptop} {
    display: flex;
    justify-content: space-around;

    .small {
      width: 40%;
    }

    .big {
      width: 60%;
    }
  }
`

interface Data {
  data: {
    sanitySiteSettings: {
      _rawDescription: React.ReactNode
      _rawAbout: React.ReactNode
      _rawTail: React.ReactNode
      title: string
      subtitle: string
      siteSEO: {
        title: string
        _rawDescription: Array<Block>
        location: string
      }
    }
  }
}

const IndexPage = ({ data }: Data): JSX.Element => {
  const { title, subtitle, _rawDescription, _rawAbout, _rawTail, siteSEO } = data.sanitySiteSettings

  return (
    <Layout>
      <SEO title={siteSEO.title} location={siteSEO.location} description={siteSEO._rawDescription} />
      <PageWrapper>
        <SideBySide className="reverse">
          <HeroVideo className="big" />
          <HomeLead title={title} subtitle={subtitle} body={_rawDescription} className="small pad" />
        </SideBySide>

        <HomePageIcons className="icons" />

        <SideBySide className="reverse tight-left">
          <AirportWeather icao="kluk" />
          <BlogRecent className="big" />
        </SideBySide>

        <HomeAbout body={_rawAbout} />

        <SideBySide>
          <div className="big pad">
            <h2>Personalized Courses</h2>
            <BlockContent body={_rawTail} />
          </div>
          <HomeContactForm className="small" />
        </SideBySide>
      </PageWrapper>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    sanitySiteSettings {
      _rawDescription
      _rawAbout
      _rawTail
      title
      subtitle
      siteSEO {
        title
        _rawDescription
        location
      }
    }
  }
`
