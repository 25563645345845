import React from 'react'
import styled from 'styled-components'
import { WiWindDeg } from 'react-icons/wi'
import { Condition } from './Condition'

const DirectionalWind = styled(WiWindDeg)`
  transform: rotate(${(props) => props.deg}deg);
`

interface Props {
  wind_dir_degrees: number
  wind_speed_kt: number
}

export const Wind = ({ wind_dir_degrees, wind_speed_kt }: Props): JSX.Element => {
  return (
    <Condition>
      <DirectionalWind deg={wind_dir_degrees} />
      <span>Wind</span>
      <span>
        {wind_dir_degrees}/{wind_speed_kt}kt
      </span>
    </Condition>
  )
}
