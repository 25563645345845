import styled from 'styled-components'

export const Condition = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  span:nth-of-type(1) {
    font-size: 0.8em;
  }

  svg {
    font-size: 2.3em;
  }
`
